(function () {
    'use strict';

    angular
        .module('AngularUtilityService', [])
        .factory('UtilityService', UtilityService);

    function UtilityService() {

        /* SERVICE OBJECT */
        var serviceCalls = {
            SortByDistance: sortByDistance,
            GetDistance: getDistanceFromLatLonInKm,
            GetStateInfo: getStateInfo,
            GetStates: getStates
        };
        return serviceCalls;

        function sortByDistance(arrayOfThings) {
            var byFilter = arrayOfThings.slice(0);
            byFilter.sort(function (a, b) {
                return a.distance - b.distance;
            });

            return byFilter;
        }

        function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
            var R = 6371; // Radius of the earth in km
            var dLat = Deg2Rad(lat2 - lat1);  // deg2rad below
            var dLon = Deg2Rad(lon2 - lon1);
            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(Deg2Rad(lat1)) * Math.cos(Deg2Rad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2)
                ;
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c; // Distance in km
            return d;
        }

        function Deg2Rad(deg) {
            return deg * Math.PI / 180;
        }

        function getStateInfo(code) {
            var states = getStates();
            for (var s = 0; s < states.length; s++) {
                if (states[s].Code == code) {
                    return states[s];
                }
            }
        }

        function getStates() {
            var states = [];
            states.push({ "Name": "Alabama", "Code": "US-AL" });
            states.push({ "Name": "Alaska", "Code": "US-AK" });
            states.push({ "Name": "Arizona", "Code": "US-AZ" });
            states.push({ "Name": "Arkansas", "Code": "US-AR" });
            states.push({ "Name": "California", "Code": "US-CA" });
            states.push({ "Name": "Colorado", "Code": "US-CO" });
            states.push({ "Name": "Connecticut", "Code": "US-CT" });
            states.push({ "Name": "Delaware", "Code": "US-DE" });
            states.push({ "Name": "Florida", "Code": "US-FL" });
            states.push({ "Name": "Georgia", "Code": "US-GA" });
            states.push({ "Name": "Hawaii", "Code": "US-HI" });
            states.push({ "Name": "Idaho", "Code": "US-ID" });
            states.push({ "Name": "Illinois", "Code": "US-IL" });
            states.push({ "Name": "Indiana", "Code": "US-IN" });
            states.push({ "Name": "Iowa", "Code": "US-IA" });
            states.push({ "Name": "Kansas", "Code": "US-KS" });
            states.push({ "Name": "Kentucky", "Code": "US-KY" });
            states.push({ "Name": "Louisiana", "Code": "US-LA" });
            states.push({ "Name": "Maine", "Code": "US-ME" });
            states.push({ "Name": "Maryland", "Code": "US-MD" });
            states.push({ "Name": "Massachusetts", "Code": "US-MA" });
            states.push({ "Name": "Michigan", "Code": "US-MI" });
            states.push({ "Name": "Minnesota", "Code": "US-MN" });
            states.push({ "Name": "Mississippi", "Code": "US-MS" });
            states.push({ "Name": "Missouri", "Code": "US-MO" });
            states.push({ "Name": "Montana", "Code": "US-MT" });
            states.push({ "Name": "Nebraska", "Code": "US-NE" });
            states.push({ "Name": "Nevada", "Code": "US-NV" });
            states.push({ "Name": "New Hampshire", "Code": "US-NH" });
            states.push({ "Name": "New Jersey", "Code": "US-NJ" });
            states.push({ "Name": "New Mexico", "Code": "US-NM" });
            states.push({ "Name": "New York", "Code": "US-NY" });
            states.push({ "Name": "North Carolina", "Code": "US-NC" });
            states.push({ "Name": "North Dakota", "Code": "US-ND" });
            states.push({ "Name": "Ohio", "Code": "US-OH" });
            states.push({ "Name": "Oklahoma", "Code": "US-OK" });
            states.push({ "Name": "Oregon", "Code": "US-OR" });
            states.push({ "Name": "Pennsylvania", "Code": "US-PA" });
            states.push({ "Name": "Puerto Rico", "Code": "US-PR" });
            states.push({ "Name": "Rhode Island", "Code": "US-RI" });
            states.push({ "Name": "South Carolina", "Code": "US-SC" });
            states.push({ "Name": "South Dakota", "Code": "US-SD" });
            states.push({ "Name": "Tennessee", "Code": "US-TN" });
            states.push({ "Name": "Texas", "Code": "US-TX" });
            states.push({ "Name": "Utah", "Code": "US-UT" });
            states.push({ "Name": "Vermont", "Code": "US-VT" });
            states.push({ "Name": "Virginia", "Code": "US-VA" });
            states.push({ "Name": "Washington", "Code": "US-WA" });
            states.push({ "Name": "Washington, D.C.", "Code": "US-DC" });
            states.push({ "Name": "West Virginia", "Code": "US-WV" });
            states.push({ "Name": "Wisconsin", "Code": "US-WI" });
            states.push({ "Name": "Wyoming", "Code": "US-WY" });
            states.push({ "Name": "Alberta", "Code": "CA-AB" });
            states.push({ "Name": "British Columbia", "Code": "CA-BC" });
            states.push({ "Name": "Manitoba", "Code": "CA-MB" });
            states.push({ "Name": "New Brunswick", "Code": "CA-NB" });
            states.push({ "Name": "Newfoundland", "Code": "CA-NF" });
            states.push({ "Name": "Nova Scotia", "Code": "CA-NS" });
            states.push({ "Name": "Prince Edward Island", "Code": "CA-PE" });
            states.push({ "Name": "Quebec", "Code": "CA-QC" });
            states.push({ "Name": "Saskatchewan", "Code": "CA-SK" });
            states.push({ "Name": "Northwest Territories", "Code": "CA-NT" });
            states.push({ "Name": "Nunavut", "Code": "CA-NU" });
            states.push({ "Name": "Yukon", "Code": "CA-YT" });
            states.push({ "Name": "Ontario", "Code": "CA-ON" });
            return states;
        }
    }
})();
