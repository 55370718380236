$(document).ready(function () {
    console.log('doc ready');

    //START hide alert on mobile nav open
    $('.nav-toggle.js-nav-toggle').on('click', function () {
        console.log('nav toggled');
        $('.global-max-width > .url').toggle();
    });
    var width = $(window).width();
    var DesktopWidth = 893;
    
    $(window).on('resize', function () {
        var showAlert = true;
        if ($(this).width() !== width) {
            width = $(this).width();
            console.log(width);

            if ($("nav.nav").css('position') === 'fixed' && $('.nav-toggle.js-nav-toggle.open').length) {
                showAlert = false;
            }

            if (showAlert) {
                $('.global-max-width > .url').show();
            } else {
                $('.global-max-width > .url').hide();
            }
        }
    });
    //END hide alert on mobile nav open

    //START On submit form turn HTML into HTML
    setTimeout(function () {
        if ($('.alert-danger').length) {
            console.log('form error');
            $('html,body').animate({
                scrollTop: $('.alert-danger').offset().top
            }, 1000);
        }


        var submitDiv = $('.contents-container div:contains("reservation-submit-header")')[0];
        if (submitDiv) {
            var a = $(submitDiv)[0].textContent;
            $(submitDiv).html(a);
            console.log('text ready');

            setTimeout(function () {
                $('html,body').animate({
                    scrollTop: $('.reservation-form-header-submitted').offset().top
                }, 1000);
            }, 1000);
        }

    }, 1000);
    //END end submit form logic

});