/*
SimpleYouTubePlayer
by John E Maddox
*/

$(document).ready(function(){
var MOBILE_WIDTH_SIZE = 1; //change this value to make the Youtube video appear as a link instead in a modal. When its a link, it will try to open a phone app. We have changed this back to off/1 as Studio C would prefer a modal still on mobile

  // display video player
  $('#mList A').on('click',function(e){

    e.preventDefault();

    // get video url
    var u = $(this).attr('href');

    // display video or go to youtube depending on window size
    // this is an attempt to load videos on mobile devices in the youtube app
    if($(window).width() > MOBILE_WIDTH_SIZE){

      // get video id
      var i = u.substring(u.search('=')+1,u.length);

      // build player
      $('#mPlayer DIV').html('<iframe width="800" height="450" src="https://www.youtube.com/embed/' + i + '" frameborder="0" allowfullscreen></iframe>');

      // display player
      $('#mPlayer').fadeIn(500);

    }else{
      window.location.href = u;
    }
  }); // eof display player

  // hide video player
  $('#mPlayer').on('click',function(e){

    // hide player
    $('#mPlayer').fadeOut(500);

    // destroy player
    $('#mPlayer DIV').empty();

    }); // eof hide player

    //custom code, we need this as if you click video before the script above fully runs the user will just get sent to YT versus seeing the pop-up
    setTimeout(function () {
        $('.wait-till-loaded').css("pointer-events", "auto"); //console.log('unlocking video'); }, 1000);
    });
});
