(function () {
    'use strict';

    //console.info("loading mapservice");

    angular
        .module('AngularMapService', [])
        .factory('MapService', MapService);

    MapService.$inject = ['$rootScope'];

    function MapService($rootScope) {

        //console.info("mapservice factory");

        var mainMap;
        var markers = [];
        var infoWindows = [];
        var baseUrl;

        /* SERVICE OBJECT */
        var serviceCalls = {
            Init: Init,
            AddMarker: AddMarker,
            AddInfoMarker: AddInfoMarker,
            SetCenter: SetCenter,
            ClearMarkers: ClearMarkers,
            GetCenter: GetCenter,
            GetNeCorner: GetNeCorner
        };
        return serviceCalls;

        function Init(mapId, options, urlbase) {
            baseUrl = urlbase;

            mainMap = new google.maps.Map(
                document.getElementById(mapId), options
            );

            google.maps.event.addListener(mainMap, 'dragend', function () {
                MapMover();
            });

            google.maps.event.addListener(mainMap, 'zoom_changed', function () {
                MapMover();
            });

        }

        function SetCenter(position) {
            mainMap.setCenter(position);
        }

        function GetCenter() {
            return mainMap.getCenter();
        }

        function GetNeCorner() {
            return mainMap.getBounds().getNorthEast();
        }

        function AddMarker(position, markerValue) {
            var newMarker = new google.maps.Marker({
                map: mainMap,
                position: position,
                label: {
                    text: markerValue.toString(),
                    color: 'rgba(255,255,255,1)',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            });

            google.maps.event.addListener(newMarker, 'click', function () {
                window.location.href = baseUrl + pos.Url.substring(1);
            });

            markers.push(newMarker);
        }

        function AddInfoMarker(position, markerIcon, windowValue) {
            var infowindow = new google.maps.InfoWindow({
                content: windowValue
            });

            infoWindows[infoWindows.length] = infowindow;

            var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
            var markerIconObj = isIE11 == true ? { url: markerIcon, scaledSize: new google.maps.Size(32, 32) } : markerIcon;

            var markerData = {
                position: position,
                map: mainMap,
                icon: markerIconObj,
                optimized: false,
            }

            //if (markerValue !== null) {
            //    markerData["label"] = {
            //        //text: markerValue.toString(),
            //        //color: 'rgba(255,255,255,1)',
            //        //fontSize: '14px',
            //        //fontWeight: 'bold'
            //    };
            //}

            var marker = new google.maps.Marker(markerData);

            marker.addListener('click', function () {
                infoWindows.forEach(function (infoWindow) {
                    infoWindow.close();
                });
                infowindow.open(mainMap, marker);
            });

            mainMap.setZoom(11);
        }

        function ClearMarkers() {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
            }
        }

        function MapMover() {
            $rootScope.$broadcast('mapMoved');
        }

    }
})();