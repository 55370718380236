$(document).ready(function () {
	// variables
	var navToggle = $('.js-nav-toggle');

	// click events
	navToggle.on('click', function (e) { e.stopPropagation(); toggleNav(e); });

	$('.js-nav-toggle-children i').on('click', function (e) { mobileNavItem(e); });

	function toggleNav(e) {
		var target = $(e.currentTarget);

		target.toggleClass('open');

		$('.nav').slideToggle();
		$('body').toggleClass('nav-open');
	}

	// clicking on items in mobile nav
    function mobileNavItem(e) {
		if (window.innerWidth <= 960) {
			e.preventDefault();
			var a = $(e.currentTarget).closest('a');
			var li = a.closest('li');

            $(li).find('> ul').slideToggle();
            $(li).toggleClass('open');
		}
	}
});