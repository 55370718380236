(function () {
    'use strict';

    angular
        .module('AngularGeoService', ['AngularWebService'])
        .factory('GeoService', GeoService);

    GeoService.$inject = ['$rootScope', '$q', 'WebService'];

    function GeoService($rootScope, $q, WebService) {

        var api;
        var LatLongData = [];
        var LocationData = [];
        var googleBase = "https://maps.googleapis.com/maps/api/geocode/json?";

        var latString;
        var lngString;
        var locString;

        /* SERVICE OBJECT */
        var serviceCalls = {
            Init: Init,
            GetUserLocation: getUserLocation,
            GetInputLocation: getInputLocation,
            GetUserStringLocation: getUserStringLocation
        };

        return serviceCalls;

        function Init(apiKey) {
            api = apiKey;
        }


        function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return undefined;
        }

        function getUserLocation() {
            var defer = $q.defer();

            if (navigator.geolocation) {
                var options = {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 60000
                };

                navigator.geolocation.getCurrentPosition(function (p) {
                    LatLongData.Latitude = p.coords.latitude;
                    LatLongData.Longitude = p.coords.longitude;
                    defer.resolve(LatLongData);
                },
                    function (e) {
                        console.log(e);
                        LatLongData.Latitude = 47.253935;
                        LatLongData.Longitude = -122.436841;
                        defer.resolve(LatLongData);
                    },
                    options);
            } else {
                console.error("navigation geolocation not enabled", LatLongData);
                defer.resolve(LatLongData);
            }
            return defer.promise;
        }

        function getInputLocation() {
            var defer = $q.defer();

            var addressString = $rootScope.inputLocation;

            if (addressString != '') {
                if (api == 'undefined' || api == null || api.indexOf('key') == -1)
                    api = 'key=AIzaSyC1sm2AjUsvvkgY2SxUEg_5BdWWCdFbi7E';
                var url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + addressString + "&" + api;

                var location = WebService.Get(url).then(function (data) {
                    if (data !== null && !data.data.error_message) {
                        if (typeof (data.data.results != 'undefined')) {
                            LatLongData.Latitude = data.data.results[0].geometry.location.lat;
                            LatLongData.Longitude = data.data.results[0].geometry.location.lng;
                            defer.resolve(LatLongData);
                        }
                    } else if (data.data.error_message) {
                        console.log(data.data.error_message);
                        defer.reject(data.data.error_message);
                        return defer.promise
                            ;
                    }
                });
            }

            return defer.promise;
        }

        function getUserStringLocation() {
            var defer = $q.defer();
            var definedLocation;

            if (typeof (LatLongData.Latitude) !== 'undefined' && typeof (LatLongData.Longitude) !== 'undefined') {
                if (typeof (LocationData.Location) == 'undefined') {
                    var url = googleBase + "latlng=" + LatLongData.Latitude + "," + LatLongData.Longitude + "&" + api;
                    var location = WebService.Get(url).then(function (data) {
                        if (data !== null && !data.data.error_message) {
                            if (typeof (data.data.results != 'undefined')) {
                                var resultString = decodeUserLocation(data.data.results);
                                defer.resolve(resultString);
                            }
                        } else if (data !== null && data.data !== null && data.data.error_message) {
                            defer.reject(console.log(data.data.error_message));
                        }
                    });
                } else {
                    defer.resolve(LocationData.Location);
                }
            } else {
                getUserLocation().then(function (result) {
                    var url = googleBase + "latlng=" + LatLongData.Latitude + "," + LatLongData.Longitude + "&" + api;
                    var location = WebService.Get(url).then(function (data) {
                        if (data !== null && !data.data.error_message) {
                            if (typeof (data.data.results != 'undefined')) {
                                var resultString = decodeUserLocation(data.data.results);
                                defer.resolve(resultString);
                            }
                        } else if (data !== null && data.data !== null && data.data.error_message) {
                            defer.reject(console.log(data.data.error_message));
                        }
                    });
                });
            }

            return defer.promise;
        }

        function decodeUserLocation(data) {
            var state = "";
            var country = "";

            var allComps;

            if (data.length > 0) {
                allComps = data[0].address_components;
            }

            for (var i = 0; i < allComps.length; i++) {
                if (allComps[i].types.includes('administrative_area_level_1')) {
                    state = allComps[i].short_name;
                }
                if (allComps[i].types.includes('country')) {
                    country = allComps[i].short_name;
                }
            }

            if (country != "" && state != "") {
                var newLocation = country + "-" + state;
                return newLocation;
            }
            else {
                console.log(data.data.error_message);
                return "US-WA";
            }
        }
    }
})();
