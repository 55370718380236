(function () {
    'use strict';

    angular
        .module('AngularWebService', [])
        .factory('WebService', WebService);

    WebService.$inject = ['$http'];

    function WebService($http) {

        /* SERVICE OBJECT */
        var serviceCalls = {
            Get: Get,
            Post: Post
        };
        return serviceCalls;

        /* SERVICE FUNCTION DEFINITIONS */
        function Get(url) {
            return $http.get(url)
                .then(function (data) {
                    return data;
                }, function (data) {
                    console.log(data);
                    return null;
                });
        }

        function Post(url, data) {
            return $http.post(url, data)
                .then(function (data) {
                    return data;
                }, function (data) {
                    console.log(data);
                    return null;
                });
        }
    }
})();