var isFormSubmitting = false;
jQuery(document).ready(function () {
    //console.log('form.all in jQuery');
    $("div[data-sf-role='form-container'] form").on('submit', function(e) {
        if(!isFormSubmitting) {
            isFormSubmitting = true;
            $('button[type="submit"]').addClass('sf-form-submitting');
            $('input[type="submit"]').addClass('sf-form-submitting');
        }
    });
});