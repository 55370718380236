angular.module('st')
    .controller('mobileMenuNavCtrl', [
        '$rootScope','$scope',
        function ($rootScope,$scope) {
            var ctrl = this;
            
                ctrl.floatingNavLocation = function() {
                    var docScrollTop = $(document).scrollTop();
                    var isMobile = window.innerWidth < 992;

                    if (docScrollTop < 75) {
                        if (isMobile) {
                            $('.menu-nav-fixed').css('top', '74px');
                        } else {
                            $('.menu-nav-fixed').css('top', '0');
                        }
                    } else {
                        $('.menu-nav-fixed').css('top', '0');
                    }
                }

            $scope.currentMenuSelection = 'Select menu';
            $scope.currentSubCatSelection = '';
            $scope.optionsExpanded = false;

            
            if(window.innerWidth < 992) {
                            var height = $('.menu-nav-desktop.menu-nav-on-page').height();
                            $('.menu-nav-desktop').removeClass('menu-nav-on-page');
                            $('.dummy-height').css('padding-top', height + 'px');
                            $('.menu-nav-desktop').addClass('menu-nav-fixed');
            }
            
            ctrl.floatingNavLocation();

            ctrl.init = function (menuSection, subCategory) {
                $scope.currentMenuSelection = menuSection;
                $scope.currentSubCatSelection = subCategory;

                ctrl.captureSubCategories();
                ctrl.setEvents();

                // each time a new image loads, call the captureSubCategories() method to update
                // the sub-sections' top and bottom points for accurate menu nav scroll
                var totalImages = $('img').filter('[src*=tmb-menu-image]').length;
                $('img').filter('[src*=tmb-menu-image]').each(function () {
                    var img = new Image();
                    img.onload = function () {
                        ctrl.captureSubCategories();
                    }
                    img.src = $(this).attr('src');
                });
            }

            ctrl.mobileDropdownClicked = function ($event) {
                $event.preventDefault();
                $scope.optionsExpanded = !$scope.optionsExpanded;
            }

            ctrl.setEvents = function () {  
                $(window).on('resize', function () {

                     if(window.innerWidth < 992) {
                            var height = $('.menu-nav-desktop.menu-nav-on-page').height();
                            $('.menu-nav-desktop').removeClass('menu-nav-on-page');
                            $('.dummy-height').css('padding-top', height + 'px');
                            $('.menu-nav-desktop').addClass('menu-nav-fixed');
                       } else {
                            $('.menu-nav-desktop').removeClass('menu-nav-fixed');
                            $('.menu-nav-desktop').addClass('menu-nav-on-page');
                       }
                       ctrl.floatingNavLocation();
                });

                $(document).on('scroll', function () {
                    var docScrollTop = $(document).scrollTop();

                    if(window.innerWidth > 991 && docScrollTop === 0) {
                        $('.menu-nav-desktop').removeClass('menu-nav-fixed');
                        $('.menu-nav-desktop').addClass('menu-nav-on-page');
                    }

                    var isMobile = window.innerWidth < 768;
                    isMobile = false;

                    if (docScrollTop < $scope.menuCategoriesBottom) {
                        for (var title in $scope.categories) {
                            var category = $scope.categories[title];
                            var offsetWithBuffer = isMobile == true ? docScrollTop + 85 : docScrollTop + 200;
                            if (offsetWithBuffer > category.top && offsetWithBuffer < category.bottom) {
                                ctrl.setSelection(title, true);
                            }
                        }
                    } else {
                        ctrl.setSelection('Select menu', true);
                    }

                    if ((docScrollTop > $scope.menuCategoriesTop) && (docScrollTop < $scope.menuCategoriesBottom)) {
                        if (isMobile == true) {
                            var height = 47;
                            $('.menu-nav-mobile.on-page').removeClass('on-page');
                            $('.dummy-height').css('padding-top', height + 'px');
                        } else {
                            var height = $('.menu-nav-desktop.menu-nav-on-page').height();
                            $('.menu-nav-desktop').removeClass('menu-nav-on-page');
                            $('.dummy-height').css('padding-top', height + 'px');
                            $('.menu-nav-desktop').addClass('menu-nav-fixed');
                        }
                    } else if (docScrollTop < $scope.menuCategoriesTop) {
                        if (isMobile == true) {
                            $('.dummy-height').css('padding-top', '0px');
                            $('.menu-nav-mobile').addClass('on-page');
                        } else {
                            $('.menu-nav-desktop').removeClass('menu-nav-fixed');
                            $('.dummy-height').css('padding-top', '0px');
                            $('.menu-nav-desktop').addClass('menu-nav-on-page');

                            if (window.innerWidth < 991) {
                            //always keep menu shown on mobile per studio c request.
                            $('.menu-nav-desktop').addClass('menu-nav-fixed');
                            }
                        }
                    }

                    ctrl.floatingNavLocation();
                });
                    
                $('body').click(function (e) {
                    var clickedDiv = $(e.target);
                    var navNotInParents = clickedDiv.parents('.menu-nav-mobile-container').length == 0;
                    var notNavContainer = clickedDiv.hasClass('.menu-nav-mobile-container') == false;
                    if (navNotInParents && notNavContainer) {
                        $scope.optionsExpanded = false;
                        $scope.$apply()
                    };
                });
            }
            
            ctrl.captureSubCategories = function () {
                var menuCatsClass = '.menu-categories';
                menuCatsClass = '.header';
                var menuSubCatsClass = '.menu-sub-category-items.shown';
                
                $scope.menuCategoriesTop = $(menuCatsClass).offset().top;
                var marginBottom = parseInt($('.menu-category').css('margin-bottom').replace('px', ''));
                $scope.menuCategoriesBottom = $scope.menuCategoriesTop + $(menuSubCatsClass).height() + marginBottom;
                $scope.categories = [];

                $('.menu-sub-category-items.shown .menu-category').each(function (i, div) {
                    var sectionTop = $(this).offset().top;
                    var sectionHeight = $(this).height();
                    var marginBottom = parseInt($(this).css('margin-bottom').replace('px', ''));
                    var title = $(this).data('title');
                    $scope.categories[title] = { top: sectionTop, bottom: sectionTop + sectionHeight + marginBottom };
                });
            }

            ctrl.menuCatClicked = function ($event, title, firstSubCatTitle, isMobile) {
                if (isMobile == true) {
                    $scope.optionsExpanded = false;
                }

                $event.preventDefault();
                $scope.currentMenuSelection = title;
                $scope.currentSubCatSelection = firstSubCatTitle;

                $('.menu-sub-category-items').removeClass('shown');
                var subCatItems = $('.menu-sub-category-items[data-category-title="' + title + '"]');
                subCatItems.addClass('shown');
                var hasDescriptiveTitle = subCatItems.children('.descriptive-title').length > 0;
                ctrl.captureSubCategories();

                var toScroll = $('.menu-nav-desktop').hasClass('menu-nav-fixed') || (isMobile == true);
                ctrl.subCatClicked(null, firstSubCatTitle, isMobile, hasDescriptiveTitle, toScroll);
            }

            ctrl.subCatClicked = function ($event, subCatTitle, isMobile, includeDescTitle, toScroll) {
                // do this so that the other-options collapse and their height isn't taken into account
                // when calculating how much to scroll, because they'll be collapsed right after this method
                // and will cause over-scrolling in some cases
                if (isMobile == true) {
                    $('.menu-nav-mobile.on-page').removeClass('options-expanded');
                }

                var clickedA = null;

                if ($event) {
                    $event.preventDefault();
                    clickedA = $($event.target);
                } else {
                    clickedA = $('a[data-title="' + subCatTitle + '"]');
                }
                $scope.currentSubCatSelection = subCatTitle;

                var sectionName = $(clickedA).data('section');
                var section = $('#' + sectionName);
                var subCatScrollBack = isMobile == true ? 70 : 170;
                var descTitleScrollback = 0;

                if (includeDescTitle == true) {
                    descTitleScrollback = isMobile == true ? 73 : 102;
                }

                var subCatsHeight = 0;
                if (isMobile == false && Object.keys($scope.categories).length == 1) {
                    subCatsHeight = 24;
                }

                var totalScrollback = subCatScrollBack + descTitleScrollback - subCatsHeight;
                var scrollTop = section.offset().top - totalScrollback;

                if (toScroll == true) {
                    $(document).scrollTop(scrollTop);
                }
            }

            ctrl.setSelection = function (selection, apply) {
                $scope.currentSubCatSelection = selection;
                $scope.optionsExpanded = false;

                if (apply == true) {
                    $scope.$apply();
                }
            }
        }
    ]);
